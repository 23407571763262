<template>
  <div>
    <a-modal
      width="400px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :closable="false"
      title="提示"
      @ok="handleSubmit"
    >
      <template slot="footer">
        <a-button
          class="cancel-btn"
          @click="closeModal"
        >
          取消
        </a-button>

        <a-button
          class="submit-btn"
          @click="handleSubmit"
        >
          确定
        </a-button>
      </template>
      <div>
        <a-radio-group :value="checkType" @change="changeCheckType">
          <a-radio value="master">主家验证</a-radio>
          <a-radio value="employ">员工验证</a-radio>
        </a-radio-group>
        <valid-master-password
          ref="masterPassword"
          v-if="isMasterValid"
          :master-phone="masterPhone"
          :service-order-id="serviceOrderId"
          :visible.sync="isMasterValid"
          @completed="validSuccess"
        />
        <valid-employ-password
          ref="employPassword"
          v-if="isEmployValid"
          :service-order-id="serviceOrderId"
          :visible.sync="isEmployValid"
          @completed="validSuccess"
        />
      </div>
    <!--      <div class="content">-->
    <!--        <span class="tips">{{ '为了避免误操作与信息安全，请输入丧属手机' + masterPhone + '收到的服务验证码。' }}</span>-->

    <!--        <a-form-->
    <!--          class="custom-compact-form"-->
    <!--          :form="form"-->
    <!--          :wrapper-col="{ span: 24 }"-->
    <!--          @submit="handleSubmit"-->
    <!--        >-->
    <!--          <a-form-item label="">-->
    <!--            <a-row class="custom-flex">-->
    <!--              <a-input-->
    <!--                v-decorator="['sms_captcha', {-->
    <!--                  normalize: this.$lodash.trim,-->
    <!--                  rules: [-->
    <!--                    { required: true, message: '请输入验证码' },-->
    <!--                  ],-->
    <!--                  validateTrigger: 'blur'-->
    <!--                }]"-->
    <!--                placeholder="请输入验证码"-->
    <!--              />-->
    <!--              <a-button type="primary" @click="getSmsCaptcha" :disabled="disabled" style="margin-left: 10px;">-->
    <!--                {{ btnTitle }}-->
    <!--              </a-button>-->
    <!--            </a-row>-->
    <!--          </a-form-item>-->
    <!--        </a-form>-->
    <!--      </div>-->
    </a-modal>
  </div>
</template>

<script>
import { sendSmsCaptcha } from '@/api/sms_captcha'
import ValidMasterPassword from '@/views/halls/show/mobile/in_use/ValidMasterPhoneNumber.vue'
import ValidEmployPassword from '@/views/halls/show/mobile/in_use/ValidEmployPhoneNumber.vue'

export default {
  name: 'ValidPassword',
  components: { ValidEmployPassword, ValidMasterPassword },
  props: {
    visible: {
      type: Boolean,
      required: true
    },

    serviceOrderId: {
      type: Number,
      required: true
    },

    masterPhone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'valid_sms' }),
      submitting: false,
      clickingSmsBtn: false,
      disabled: false,
      btnTitle: '获取验证码',
      checkType: 'master',
      isMasterValid: true,
      isEmployValid: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    changeCheckType(val) {
      this.checkType = val.target.value
      this.isEmployValid = this.checkType === 'employ'
      this.isMasterValid = this.checkType === 'master'
      console.log(val)
    },
    getSmsCaptcha(e) {
      e.preventDefault()
      if (this.clickingSmsBtn) {
        this.$warning({
          title: '请勿重复点击',
          content: ''
        })
        return
      }

      this.clickingSmsBtn = true
      sendSmsCaptcha({ service_order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.validateBtn()
        }
        this.clickingSmsBtn = false
      })
    },

    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          // eslint-disable-next-line no-unused-expressions
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time -= 1
        }
      }, 1000)
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.isMasterValid === true) {
        this.$refs.masterPassword.handleSubmit()
      } else if (this.isEmployValid === true) {
        this.$refs.employPassword.handleSubmit()
      }
      // this.form.validateFields((err, values) => {
      //   if (!err) {
      //     this.submitting = true
      //     verifySmsCaptcha(Object.assign({}, { service_order_id: this.serviceOrderId }, values)).then((res) => {
      //       if (res.code === 0) {
      //         // 告知父组件已完成
      //         this.isShow = false
      //         this.$emit('completed')
      //       }
      //       this.submitting = false
      //     })
      //   }
      // })
    },
    validSuccess() {
      // 告知父组件已完成
      this.isShow = false
      this.$emit('completed')
    },
    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-content {
  background-color: #2a4c39;
}

/deep/ .ant-modal-header {
  border-bottom: 1px solid #848484;
  text-align: center;
  background-color: #2a4c39 !important;

  .ant-modal-title {
    color: @white !important;
  }
}

/deep/ .ant-modal-footer {
  border-top: none;
  text-align: center;
  background-color: #2a4c39 !important;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;

  .tips {
    margin-bottom: 20px;
    color: #ffd794ff;
  }
}

.cancel-btn {
  width: 150px;
  border: none;
  color: @white;
  background-color: #37543e;
}

.submit-btn {
  width: 150px;
  border: none;
  background-color: #ffd794ff;
}

/deep/ .submit-btn.ant-btn:hover {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .submit-btn.ant-btn:focus {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .ant-divider-horizontal {
  background-color: #848484;
}
</style>
